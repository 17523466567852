














































import {Component, Vue} from 'vue-property-decorator';
import VueScrollTo from 'vue-scrollto';
import Faq from '@/components/Faq.vue';
import Footer from '@/components/Footer.vue';
import JoinOurList from '@/components/modals/JoinOurList.vue';
Vue.use(VueScrollTo);

@Component({
  components: {Faq, Footer, JoinOurList},
})
export default class Landing extends Vue {
  showJoinModal = false;
}
